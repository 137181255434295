/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

::-webkit-scrollbar {
  width: 9px;
  overflow-y: scroll;
  background: rgb(4, 200, 115);
  border: 3px white solid;
  box-shadow: inset 0 0 4px #08cc40;
}

::-webkit-scrollbar-thumb {
  background: rgb(16, 76, 40);
  border-radius: 20px;

}
table{
  border: solid rgba(51, 106, 65, 0.39) 1px;
  margin-left:30px;
  width: 95%;
  text-transform: uppercase;
}

table th{
  position: sticky;

  top: -0.5px;
  background-color: #08712fc1 !important;
  color: white !important;
}
